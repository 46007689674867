import IndexPage from './index';

// THE 'prismic { allAuthors {...}} IS NOT NEEDED, just to prevent validation error ('$lang is defined but never used')
export const query = graphql`
query($lang: String = "en-gb") {
  prismic {
    allAuthorss(lang: $lang) {
      edges {
        node {
          firstname
          lastname
          image
          _linkType
        }
      }
    }
  }

  ...IndexFragment
}
`

export default IndexPage
